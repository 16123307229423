<script setup lang="ts">

// TODO: load these from CMS?
const menuColumns = [
  {
    name: 'Welkom',
    items: [
      { name: 'Home', to: '/' },
      { name: 'Over ons', to: '/over-ons' },
      { name: 'Contact', to: '/contact' },
    ],
  },
  {
    name: 'Vrijwilligers',
    items: [
      { name: 'Informatie', to: '/voor-vrijwilligers' },
      { name: 'Vacaturebank', to: '/vacancies/search' },
      { name: 'Profiel plaatsen', to: '/signup' },
    ],
  },
  {
    name: 'Organisaties',
    items: [
      { name: 'Informatie', to: '/voor-organisaties' },
      { name: 'Aanmelden', to: '/organizations/signup' },
      { name: 'Vacature plaatsen', to: '/vacancies/add' },
    ],
  },
]

const { findSystemPageById } = useSystemPages()
const signupPageVolunteer = findSystemPageById(10)
const signupPageOrganization = findSystemPageById(2)

const { t } = useI18n({ useScope: 'local' })

const ctaButtons = [
  { title: t('ctaButtons.signupVolunteer'), to: signupPageVolunteer?.path || '#' },
  { title: t('ctaButtons.signupOrganization'), to: signupPageOrganization?.path || '#' },
]

const slogan: string | undefined = undefined

const { tenant } = useTenant()
const isUsingLegacyFooter = computed(() => tenant.value == 'public')

</script>

<template>
  <FooterMaud
    v-if="!isUsingLegacyFooter"
    :menu-columns="menuColumns"
    :slogan="slogan"
    :cta-buttons="ctaButtons"
    :powered-by="{
      text: 'Powered by Volunteerly',
      to: 'https://www.volunteerly.nl',
    }"
  />
  <FooterLegacy v-else />
</template>

<i18n lang="json">
{
  "en": {
    "ctaButtons": {
      "signupVolunteer": "Sign up as volunteer",
      "signupOrganization": "Sign up as organization"
    }
  },
  "nl": {
    "ctaButtons": {
      "signupVolunteer": "Registreer als vrijwilliger",
      "signupOrganization": "Registreer als organisatie"
    }
  }
}
</i18n>

<script setup lang="ts">
const {
  menuSlug = 'main',
  contentPathPrefix = undefined,
} = defineProps<{
  /**
   * The slug of the menu to fetch.
     */
  menuSlug?: string
  /**
   * The prefix to add to the paths of the menu items that link to content pages.
   * Used when nesting pages in a specific scope.
   */
  contentPathPrefix?: string
}>()

// Initialing this before the defaults didn't work
const { contentPathPrefix: defaultContentPathPrefix } = useAppConfig()

// Prefix content items, if a content paths prefix is set.
// It would probably be better to have a project-wide config or function to call for this,
// because we have the same issue on the content pages overview.
const prefixedPath = (item: ContentMenuItem) => {
  const prefix = contentPathPrefix || defaultContentPathPrefix
  if (!contentPathPrefix || item.systemPageId) {
    return item.path
  }
  return prefix + item.path
}

const { parentMenuItems, activeChildMenuItems, activeMainItem } = await useCmsMenu(menuSlug)

// Quick textcolor fix for Nijmegen
// const textColor = 'text-muted-400 hover:text-muted-500 dark:text-muted-500 dark:hover:text-muted-400'
const textColor = 'text-muted-600 hover:text-muted-500 dark:text-muted-300 dark:hover:text-muted-400'
const activeTextColor = '!border-primary-500 !text-muted-900 dark:!text-muted-100'

const { theme } = useHeaderStyles()

// font-menu is declared in fonts.css
const fontClass = computed(() => {
  if (theme.value.cmsMenu.fontWeight === 'semibold') {
    return 'font-semibold text-center'
  }
  return ''
})

const borderClasses = computed(() => {
  // Used for the legacy layout to draw a border between the CMS menu and the auth menu
  // So actually we should only do this when the auth menu is visible, so when authenticated.
  if (theme.value.cmsMenu.hasBorders) {
    // Use CSS variable directly
    return 'border-b border-[var(--color-theme-header-border)] dark:border-muted-700'
  }
  return ''
})

</script>

<template>
  <div>
    <div :class="borderClasses">
      <Container class="hidden justify-between overflow-x-auto md:flex">
        <nav class="flex transition-all duration-300 lg:overflow-x-hidden">
          <NuxtLink
            v-for="(item, index) in parentMenuItems"
            :key="index"
            :to="prefixedPath(item)"
            :class="{
              '!border-primary-500 !text-muted-900 dark:!text-muted-100':
                item == activeMainItem,
              [textColor]: true,
              [fontClass]: true,
            }"
            class="texttransition-colors min-w-max border-b-2 border-transparent p-3 duration-300 first:pl-0"
            exact-active-class="!border-primary-500 !text-muted-900 dark:!text-muted-100 dark:!border-primary-200"
          >
            <BaseText size="sm" weight="medium">
              {{ item.name }}
            </BaseText>
          </NuxtLink>
        </nav>
      </Container>
    </div>
    
    <div
      :class="[
        'hidden w-full transition-all duration-300 md:block',
        activeChildMenuItems.length > 0 ? borderClasses : ''
      ]"
    >
      <Container class="flex overflow-x-auto md:overflow-x-hidden">
        <NuxtLink
          v-for="(item, index) in activeChildMenuItems"
          :key="index"
          :to="prefixedPath(item)"
          :class="{
            [textColor]: true,
          }"
          class="flex min-w-max items-center justify-center border-b-2 border-transparent p-3 text-center transition-colors duration-300 first:pl-0"
          :exact-active-class="activeTextColor"
        >
          <BaseText size="sm" weight="medium">
            {{ item.name }}
          </BaseText>
        </NuxtLink>
      </Container>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  // Prop to override the global color mode
  colorMode?: 'light' | 'dark'
}>()

const globoalColorMode = useColorMode()

const { appName } = useAppName()

const { currentCenterProfile } = useCurrentCenter()

const isDarkMode = computed(() => {
  return props.colorMode == 'dark' || globoalColorMode.value == 'dark'
})

const key = ref(0)

const logoUrl = computed(() => {
  if (isDarkMode.value && currentCenterProfile.value.websiteLogoDarkModeUrl) {
    return currentCenterProfile.value.websiteLogoDarkModeUrl
  }
  return currentCenterProfile.value.websiteLogoUrl
})

// Quickfix for dark mode on initial page load, seems to be a bug in useColorMode?
onMounted(() => {
  key.value++
})
</script>

<template>
  <img
    v-if="logoUrl"
    :key="key"
    :src="logoUrl"
    :alt="`Logo ${appName}`"
  >
  <div v-else>
    <BaseHeading as="h1">
      {{ appName }}
    </BaseHeading>
  </div>
</template>

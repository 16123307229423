<template>
  <FooterLegacyColumn name="Vrijwilligerswerk Gelderland" :low-opacity="false">
    <p class="mb-4 opacity-65">
      wij zijn onderdeel van vrijwilligerswerk gelderland. Samen met 8 andere
      organisaties helpen wij de omgeving.
    </p>
    <NuxtLink to="/gelderse-handen">
      bekijk alle organisaties →
    </NuxtLink>
  </FooterLegacyColumn>
</template>

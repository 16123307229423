<script setup lang="ts">
const { menuItems } = await useAuthMenu()

const { classes, theme } = useHeaderStyles()

const menuStyle = theme.value.authMenu.style

// Tabs styling
const tabsBackgroundColorClass = computed(() => `bg-[var(--color-theme-menu-tabs-background)] dark:bg-muted-900`)
const tabStyle = computed(() => 'border-[var(--color-theme-menu-tabs-border)] dark:border-muted-700 w-fit items-center rounded-t-xl px-4 border-t border-r border-l ' + tabsBackgroundColorClass.value)

const useIcons = true

</script>

<template>
  
  <div
    :class="[
      'hidden w-full overflow-x-auto transition-all duration-300 md:block',
      menuStyle === 'tab' ? 'mt-4' : '',
    ]"
    class=""
  >
    <Container class="" :full-width="false">
      <nav :class="menuStyle === 'tab' ? tabStyle : ''" class="flex items-end">
        <NuxtLink
          v-for="(item, index) in menuItems"
          :key="index"
          :to="item.to"
          class="flex items-center justify-center p-3 text-center transition-colors duration-300 first:pl-0"
          :class="[
            classes.menuItem.base,
            'border-b-2'
          ]"
          :exact-active-class="classes.menuItem.active"
        >
          <Icon
            v-if="useIcons"
            :name="item.icon?.name"
            class="mr-1 size-5"
          />
          <BaseText size="sm" weight="medium">
            {{ item.name }}
          </BaseText>
        </NuxtLink>
      </nav>
    </Container>
  </div>
</template>

<script setup lang="ts">
const { currentCenter } = useCurrentCenter()
</script>

<template>
  <FooterLegacyColumn name="Adres">
    <ul class="flex flex-col gap-1 lowercase">
      <li>{{ currentCenter.street }} {{ currentCenter.houseNumber }}</li>
      <li>{{ currentCenter.postcode }} {{ currentCenter.city }}</li>
      <li v-if="currentCenter.phoneNumber">
        {{ currentCenter.phoneNumber }}
      </li>
      <li>
        <NuxtLink
          :to="`https://maps.google.com/?q=${currentCenter.formattedAddress}`"
          target="_blank"
          rel="noopener noreferrer"
          class=""
        >
          <Icon name="ph:map-pin-duotone" />
          bekijk in Google Maps
        </NuxtLink>
      </li>
    </ul>
  </FooterLegacyColumn>
</template>

<script setup lang="ts">
const { columns } = await useFooterItems()
const row1 = columns.slice(0, 4)

const hasItemsInFirstRow = row1.some(column => column.items.length > 0)
</script>

<template>
  <FooterLegacyContainer>
    <FooterLegacyMenuColumn
      v-for="column in row1"
      :key="column.name"
      :column="column"
    />

    
    <FooterLegacyDivider v-if="hasItemsInFirstRow" class="hidden sm:block" />

    
    <FooterLegacyBottomRow />

    
    <FooterLegacySocialMedia />

    
    <FooterLegacySub />
  </FooterLegacyContainer>
</template>

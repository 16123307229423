<script setup lang="ts">
const props = withDefaults(
  defineProps<{ menuSlug?: string, contentPathPrefix?: string }>(),
  {
    menuSlug: 'main',
    contentPathPrefix: undefined,
  },
)

const { isAuthenticated } = useAuth()
const { i18nEnabled } = useTenantConfig()

const { parentMenuItems, getChildMenuItemsForMainItem } = await useCmsMenu(
  props.menuSlug,
)

// Get authenticated menu items when user is authenticated
const { menuItems: authMenuItems } = isAuthenticated.value ? await useAuthMenu() : { menuItems: ref([]) }

const { isOpen, toggle } = useSidebar()

const menuItemClasses
  = 'pl-0 flex text-muted-500 hover:text-muted-500 dark:text-muted-400 dark:hover:text-muted-300 border-b-2 border-transparent px-3 py-2.5 transition-colors duration-300'

// The 'mb-2' is to add a bit of space below the underline
const menuItemActiveClass
  = '!border-primary-500 !text-muted-900 dark:!text-muted-100 mb-2'

const router = useRouter()

watch(router.currentRoute, () => {
  if (isOpen.value) {
    toggle()
  }
})

// TODO: duplicate code with CmsMenu.
// Initialing this before the defaults didn't work
const { contentPathPrefix: defaultContentPathPrefix } = useAppConfig()

// Prefix content items, if a content paths prefix is set.
// It would probably be better to have a project-wide config or function to call for this,
// because we have the same issue on the content pages overview.
const prefixedPath = (item: ContentMenuItem) => {
  const prefix = props.contentPathPrefix || defaultContentPathPrefix
  if (!props.contentPathPrefix || item.systemPageId) {
    return item.path
  }
  return prefix + item.path
}
</script>

<template>
  <div
    v-if="isOpen"
    role="button"
    tabindex="0"
    class="fixed z-50 size-full"
    @click="toggle"
  />
  <div
    class="pointer-events-none fixed start-0 top-0 !z-[60] flex h-full xl:z-10"
  >
    <div
      class="border-muted-200 dark:border-muted-700 dark:bg-muted-800 pointer-events-auto relative z-10 flex h-full w-[300px] flex-col justify-between overflow-x-hidden overflow-y-scroll border-r bg-white pl-4 pt-4 transition-all duration-300"
      :class="
        isOpen
          ? ''
          : 'translate-x-[calc(-100%_-_80px)] rtl:translate-x-[calc(100%_+_80px)]'
      "
    >
      <nav>
        <slot name="top-content" />

        <div class="mr-5 mt-3 flex items-center justify-between pb-4">
          <BaseHeading as="h2" class="pb-1">
            Menu
          </BaseHeading>
          <ToolbarAccountMenu v-if="isAuthenticated" />
        </div>

        
        <template v-for="(item, index) in parentMenuItems" :key="index">
          <NuxtLink
            :to="prefixedPath(item)"
            :class="menuItemClasses"
            :exact-active-class="menuItemActiveClass"
          >
            <BaseText size="sm" weight="medium">
              {{ item.name }}
            </BaseText>
          </NuxtLink>

          <NuxtLink
            v-for="(childItem, childIndex) in getChildMenuItemsForMainItem(
              item,
            )"
            :key="childIndex"
            :to="prefixedPath(childItem)"
            :class="menuItemClasses"
            class="ml-2 flex items-center gap-x-2"
            :exact-active-class="menuItemActiveClass"
          >
            <Icon name="ph:circle-fill" class="h-1" />
            <BaseText size="sm" weight="medium">
              {{ childItem.name }}
            </BaseText>
          </NuxtLink>
        </template>

        
        <template v-if="isAuthenticated">
          <div class="border-muted-200 dark:border-muted-700 my-4 border-t" />
          <NuxtLink
            v-for="(item, index) in authMenuItems"
            :key="'auth-' + index"
            :to="item.to"
            :class="menuItemClasses"
            :exact-active-class="menuItemActiveClass"
            class="flex items-center gap-x-2"
          >
            <Icon
              v-if="item.icon?.name"
              :name="item.icon.name"
              class="size-5"
            />
            <BaseText size="sm" weight="medium">
              {{ item.name }}
            </BaseText>
          </NuxtLink>
        </template>

        <ToolbarSignIn v-if="!isAuthenticated" class="mt-2" />
      </nav>

      <div class="flex items-start gap-3 pb-8">
        <ToolbarLanguage v-if="i18nEnabled" />
        <BaseThemeToggle />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
// Loading the column titles from the center profile is not ideal.
// A better solution would be to store it on the menu model, so that we
// can also localize it better. It'll require a frontend feature to edit menus,
// so something for later.
const { currentCenterProfile } = await useCurrentCenter()
</script>

<template>
  <FooterLegacyColumn
    :name="currentCenterProfile.footerColumn8Title"
    class="lowercase"
    :low-opacity="false"
  >
    <img
      v-if="currentCenterProfile.showAnbiLogo"
      class="w-10 pb-3"
      src="/img/logos/logo-anbi.png"
      alt=""
    >
    <img
      v-if="currentCenterProfile.showNovLogo"
      class="w-11"
      src="/img/logos/logo-nov.png"
      alt=""
    >
    <p class="whitespace-break-spaces">
      {{ currentCenterProfile.footerColumn8Content }}
    </p>
  </FooterLegacyColumn>
</template>
